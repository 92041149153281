import axios from 'axios';
import configData from '../config/config.json';
import { getIdToken } from '@/services/auth/index';

const apiClient = axios.create({
  baseURL: configData.inventory.baseURL,
  headers: {
    Accept: 'application/json',
    'x-api-key': configData.inventory.apiKey,
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getIdToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err),
);

export async function updateInventoryItem(inventory) {
  const params = new URLSearchParams();
  params.append('serialNumber', inventory.serialNumber);
  params.append('DPNumber', inventory.DPNumber);
  const options = {
    method: 'PATCH',
    url: `update`,
    data: inventory,
    params,
  };
  const { data } = await apiClient(options);
  return data;
}

export async function createInventoryItem(inventory) {
  const options = {
    method: 'POST',
    data: inventory,
  };
  const { data } = await apiClient(options);
  return data;
}

export async function uploadInventoryItems(inventories) {
  const options = {
    method: 'POST',
    url: `bulk`,
    data: inventories,
  };
  const { data } = await apiClient(options);
  return data;
}

export async function retrieveInventory({
  totalToRetrieve,
  sortField,
  sortOrder,
  offset,
  hideDeinstalled = true,
}) {
  const params = new URLSearchParams();
  params.append('totalToRetrieve', totalToRetrieve);
  params.append('sortField', sortField);
  params.append('sortOrder', sortOrder);
  params.append('offset', offset);
  params.append('hideDeinstalled', hideDeinstalled.toString());

  const options = {
    method: 'GET',
    params,
    url: 'search',
  };

  const { data } = await apiClient(options);
  return data;
}

function buildSearchCriteria(params, searchCriteria) {
  const searchKeys = Object.keys(searchCriteria);
  searchKeys.forEach((key) => {
    if (searchCriteria[key]) {
      params.append(key, searchCriteria[key]);
    }
  });
  return params;
}

export async function searchInventory({
  totalToRetrieve,
  sortField,
  sortOrder,
  offset,
  searchCriteria,
  hideDeinstalled = true,
  asCsv = false,
}) {
  const params = new URLSearchParams();
  params.append('hideDeinstalled', hideDeinstalled.toString());
  buildSearchCriteria(params, searchCriteria);
  let url = 'search';
  if (asCsv) {
    url = 'searchcsv';
    params.append('totalToRetrieve', 500000);
  } else {
    params.append('totalToRetrieve', totalToRetrieve);
    params.append('sortField', sortField);
    params.append('sortOrder', sortOrder);
    params.append('offset', offset);
  }
  const options = {
    method: 'GET',
    params,
    url,
  };
  const { data } = await apiClient(options);
  return data;
}

export async function getInventoryCsv({ url }) {
  try {
    const response = await axios({
      url,
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/zip',
        'Content-Type': 'application/zip',
      },
    });
    if (response.headers['Content-Length'] == 0) {
      return new ArrayBuffer(0);
    }

    return response.data;
  } catch (error) {
    console.log('axios retrieval error', error);
    return new ArrayBuffer(0);
  }
}
