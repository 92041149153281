<template>
  <div id="mapContainer" ref="map" :style="normalMapStyle"></div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters, mapMutations } from 'vuex';
import loadJs from '@/lib/loadJs';
import loadCss from '@/lib/loadCss';

export default defineComponent({
  name: 'InventoryMap',
  props: {
    latitude: {
      type: Number,
      required: true,
      default: undefined,
    },
    longitude: {
      type: Number,
      required: true,
      default: undefined,
    },
  },
  data() {
    return {
      initComplete: false,
      map: undefined,
      platform: undefined,
      defaultLayers: undefined,
      behavior: undefined,
      marker: undefined,
      normalMapStyle: {
        width: 'auto',
        height: '300px',
      },
    };
  },
  computed: {
    ...mapGetters('storeHereMaps', [
      'coreLoaded',
      'serviceLoaded',
      'uiLoaded',
      'eventsLoaded',
      'uiCssLoaded',
      'mainLoaded',
    ]),
  },
  created() {
    if (!this.mainLoaded) {
      loadJs(
        'https://js.api.here.com/v3/3.1/mapsjs-core.js',
        () => {
          this.SET_CORE_LOADED(true);
        },
        document.body,
        false,
        true,
      );
    }
    loadJs(
      'https://js.api.here.com/v3/3.1/mapsjs-service.js',
      () => {
        this.SET_SERVICE_LOADED(true);
      },
      document.body,
      false,
      true,
    );
    loadJs(
      'https://js.api.here.com/v3/3.1/mapsjs-ui.js',
      () => {
        this.SET_UI_LOADED(true);
      },
      document.body,
      false,
      true,
    );
    loadJs(
      'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js',
      () => {
        this.SET_EVENTS_LOADED(true);
      },
      document.body,
      false,
      true,
    );
    loadCss(
      'https://js.api.here.com/v3/3.1/mapsjs-ui.css',
      () => {
        this.SET_UI_CSS_LOADED(true);
      },
      document.head,
    );
  },
  mounted() {
    this.getMap(() => {
      this.initMap(() => {});
    });
  },
  beforeDestroy() {
    // this.map.dispose();
    if (this.map && this.marker) {
      this.map.removeObject(this.marker);
    }
    this.map = undefined;
    this.defaultLayers = undefined;
    this.behavior = undefined;
    this.platform = undefined;
    this.marker = undefined;
    this.$refs.map = undefined;
  },
  methods: {
    ...mapMutations('storeHereMaps', [
      'SET_CORE_LOADED',
      'SET_SERVICE_LOADED',
      'SET_UI_LOADED',
      'SET_EVENTS_LOADED',
      'SET_UI_CSS_LOADED',
    ]),
    initMap(callback) {
      if (this.initComplete) callback();
      else {
        // eslint-disable-next-line no-undef
        this.platform = new H.service.Platform({
          apikey: this.$configData.mapConfig.apiKey,
        });
        const pixelRatio = window.devicePixelRatio || 1;
        this.defaultLayers = this.platform.createDefaultLayers({
          tileSize: pixelRatio === 1 ? 256 : 512,
          ppi: pixelRatio === 1 ? undefined : 320,
        });
        this.defaultLayers.crossOrigin = true;
        // eslint-disable-next-line no-undef
        this.map = new H.Map(
          this.$refs.map,
          this.defaultLayers.vector.normal.map,
          {
            center: {
              lat: this.latitude,
              lng: this.longitude,
            },
            pixelRatio,
          },
        );
        window.addEventListener('resize', () =>
          this.map.getViewPort().resize(),
        );
        // eslint-disable-next-line no-undef
        this.behavior = new H.mapevents.Behavior(
          // eslint-disable-next-line no-undef
          new H.mapevents.MapEvents(this.map),
        );

        // eslint-disable-next-line no-undef
        this.marker = new H.map.Marker({
          lat: this.latitude,
          lng: this.longitude,
        });
        this.map.addObject(this.marker);
        this.map.setZoom(14);
        this.initComplete = true;
        callback();
      }
    },
    getMap(callback) {
      let vm = this;
      function checkIfLoaded() {
        if (vm.mainLoaded) callback();
        else setTimeout(checkIfLoaded, 200);
      }
      checkIfLoaded();
    },
  },
});
</script>
