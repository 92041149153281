<template>
  <div class="p-05" @keyup.enter="onSearch">
    <p class="is-size-3 has-text-centered">Search</p>
    <p
      class="has-text-primary has-text-centered has-text-weight-semibold"
      style="margin: 1em 0em"
    >
      Enter your inventory search criteria below:
    </p>
    <b-field label="DPNumber">
      <b-input v-model="localSearchCriteria.DPNumber"> </b-input>
    </b-field>
    <b-field label="Work Order No.">
      <b-input v-model="localSearchCriteria.workOrderNumber"> </b-input>
    </b-field>
    <b-field label="Serial No.">
      <b-input v-model="localSearchCriteria.serialNumber"> </b-input>
    </b-field>
    <b-field label="Description">
      <b-input v-model="localSearchCriteria.description"> </b-input>
    </b-field>
    <b-field label="Inventory Type">
      <b-select v-model="localSearchCriteria.inventoryType" @click.prevent="">
        <option
          v-for="item in inventoryTypes"
          :key="item.value"
          :value="item.value"
          @click.prevent=""
        >
          {{ item.name }}
        </option>
      </b-select>
    </b-field>
    <b-field label="Thorough Due">
      <b-input v-model="localSearchCriteria.thoroughDue"> </b-input>
    </b-field>
    <p class="has-text-weight-semibold">Installed between:</p>
    <b-field>
      <b-datepicker
        v-model="localSearchCriteria.installDateFrom"
        placeholder="Earliest..."
        icon="calendar"
        trap-focus
        :first-day-of-week="1"
      >
      </b-datepicker>
    </b-field>
    <p class="has-text-weight-semibold">and:</p>
    <b-field>
      <b-datepicker
        v-model="localSearchCriteria.installDateTo"
        placeholder="Latest..."
        icon="calendar"
        trap-focus
        :first-day-of-week="1"
      >
      </b-datepicker>
    </b-field>
    <div class="buttons">
      <b-button type="is-primary" icon-left="search-plus" @click="onSearch"
        >Search</b-button
      >
      <b-button type="is-dark" icon-left="search-minus" @click="onClear"
        >Clear</b-button
      >
      <b-button type="is-neutral" @click="onClose">Close</b-button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'InventorySearchSideBar',
  data() {
    return {
      inventoryTypes: this.$configData.inventory.inventoryTypes,
      localSearchCriteria: {
        DPNumber: '',
        workOrderNumber: '',
        thoroughDue: '',
        serialNumber: '',
        inventoryType: '',
        installDateFrom: undefined,
        installDateTo: undefined,
        description: '',
      },
    };
  },
  computed: {
    ...mapGetters('storeInventory', ['searchCriteria']),
  },
  watch: {
    searchCriteria: {
      handler: function (newVal) {
        this.localSearchCriteria = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('storeInventory', ['setSearchCriteria', 'setInitialState']),
    onSearch() {
      this.setSearchCriteria(this.localSearchCriteria);
      this.$emit('search', true);
    },
    onClose() {
      this.$emit('close', false);
    },
    onClear() {
      this.setInitialState();
    },
  },
});
</script>

<style scoped>
.p-05 {
  margin: 0.5rem;
}
</style>
