<template>
  <div class="p-1">
    <b-loading
      :is-full-page="false"
      :active.sync="loading"
      :can-cancel="false"
    ></b-loading>
    <p class="is-size-3 has-text-centered">Inventory Upload</p>
    <p class="mt-2 has-text-weight-semibold has-text-primary has-text-centered">
      Click the button to prepare a CSV for upload
    </p>
    <br />
    <div style="display: flex; justify-content: space-around">
      <csv-loader
        :store-module-action="'storeInventory/'"
        :write-to-store="false"
        button-label="Prepare CSV"
        @loading="setFileLoading"
        @json-data="prepareJson"
      ></csv-loader>
      <b-button
        type="is-primary"
        :disabled="preventUpload"
        @click.prevent="uploadData()"
        >Upload Data</b-button
      >
    </div>
    <p class="has-text-danger">{{ error }}</p>
    <div
      v-if="countMissingDPNumber > 0 || countMissingDPNumber > 0"
      class="has-text-danger"
    >
      <p>Records with missing DP Number: {{ countMissingDPNumber }}</p>
      <p>Records with missing Serial Number: {{ countMissingSerialNumber }}</p>
    </div>
    <p
      v-if="rowCount && !preventUpload"
      class="has-text-primary has-text-centered"
      style="margin-top: 1rem"
    >
      Ready to upload {{ rowCount }} inventory items
    </p>

    <div class="has-text-centered" style="margin-top: 5rem">
      <a
        :href="csvTemplate"
        download="inventoryManagerTemplate.csv"
        class="has-text-link"
        >{{ $t('Download CSV Template') }}</a
      >
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import CsvLoader from '@/components/ofsc/loaders/CsvLoader';
import { uploadInventoryItems } from '@/services/inventoryService';
import InventoryLoadResults from './InventoryLoadResults';

export default defineComponent({
  name: 'InventoryUpload',
  components: {
    CsvLoader,
  },
  data() {
    return {
      maxRows: this.$configData.inventory.maxUploadRows,
      preventUpload: true,
      data: [],
      error: '',
      countMissingSerialNumber: 0,
      countMissingDPNumber: 0,
      loading: false,
    };
  },
  computed: {
    rowCount() {
      return this.data.length;
    },
    csvTemplate() {
      return require('@/assets/csv/inventoryManagerTemplate.csv').default;
    },
  },
  methods: {
    setFileLoading(loading) {
      if (loading) {
        this.preventUpload = true;
        this.loading = true;
        this.error = '';
      }
    },
    prepareJson(data) {
      try {
        this.preventUpload = true;
        this.loading = true;
        this.error = '';
        if (!data) {
          this.error =
            'There was a problem preparing your CSV, please contact Leadent support and provide the file you were attempting to upload';
          return;
        }
        if (data.length > this.maxRows) {
          this.error = `Maximum rows for upload is ${this.maxRows}`;
          return;
        }
        this.data = data;
        this.validateData();
        if (
          this.countMissingSerialNumber === 0 &&
          this.countMissingDPNumber === 0
        ) {
          this.preventUpload = false;
        }
      } finally {
        this.loading = false;
      }
    },
    validateData() {
      this.countMissingSerial = 0;
      this.countMissingDPNumber = 0;
      this.data.forEach((item) => {
        if (!item.DPNumber) {
          this.countMissingDPNumber++;
        }
        if (!item.serialNumber) {
          this.countMissingSerial++;
        }
      });
    },
    async uploadData() {
      try {
        this.loading = true;
        const result = await uploadInventoryItems(this.data);
        this.$emit('load-complete', true);
        this.$buefy.modal.open({
          parent: this,
          component: InventoryLoadResults,
          props: {
            data: result,
          },
        });
      } catch (err) {
        this.error = err.message;
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style>
.p-1 {
  padding: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}
</style>
