<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <h3 class="is-size-4">{{ $t('Inventory Load Results') }}</h3>
              <div class="block">
                <b-radio
                  v-model="filterRecordsBy"
                  name="all"
                  native-value="all"
                >
                  Show All
                </b-radio>
                <b-radio
                  v-model="filterRecordsBy"
                  name="successful"
                  native-value="successful"
                >
                  Successful
                </b-radio>
                <b-radio
                  v-model="filterRecordsBy"
                  name="failed"
                  native-value="failed"
                >
                  Failed
                </b-radio>
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <p>Successful: {{ successCount }}</p>
              <p>Failed: {{ failCount }}</p>
              <b-table
                :data="filteredData"
                detailed
                detail-key="resultId"
                paginated
                per-page="10"
                sticky-header
                height="800px;"
              >
                <b-table-column
                  v-slot="props"
                  field="resultId"
                  :label="$t('Result ID')"
                  sortable
                  numeric
                >
                  {{ props.row.resultId }}</b-table-column
                >

                <b-table-column
                  v-slot="props"
                  field="success"
                  :label="$t('Successful?')"
                  sortable
                >
                  {{ props.row.success ? 'Yes' : 'No' }}</b-table-column
                >

                <b-table-column
                  v-slot="props"
                  field="props.row.inventoryItem"
                  label="DP Number"
                  sortable
                  >{{ props.row.inventoryItem.DPNumber }}</b-table-column
                >
                <b-table-column
                  v-slot="props"
                  field="props.row.inventoryItem"
                  :label="$t('Serial Number')"
                  sortable
                >
                  {{ props.row.inventoryItem.serialNumber }}
                </b-table-column>
                <template slot="detail" slot-scope="props">
                  <article>
                    <div>
                      <div class="content">
                        <p v-if="props.row.error">
                          <strong>{{ 'Error details' }}</strong>
                          <br />
                          <strong
                            >DP Number:
                            {{ props.row.inventoryItem.DPNumber }}</strong
                          >
                          <br />
                          <strong
                            >Serial Number:
                            {{ props.row.inventoryItem.serialNumber }}</strong
                          >
                          <br />
                          <span> Error: {{ props.row.error }} </span>
                        </p>
                        <p>
                          {{ JSON.stringify(props.row.inventoryItem, null, 2) }}
                        </p>
                      </div>
                    </div>
                  </article>
                </template>
              </b-table>
            </slot>
            <div class="modal-footer">
              <slot name="footer">
                <b-button type="is-primary" @click="$emit('close')">
                  {{ $t('Close') }}
                </b-button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'InventoryLoadResults',
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      filterRecordsBy: 'all',
    };
  },
  computed: {
    successRows() {
      return this.data.filter((row) => {
        return row.success;
      });
    },
    failRows() {
      return this.data.filter((row) => {
        return !row.success;
      });
    },
    filteredData() {
      if (this.filterRecordsBy === 'failed') {
        return this.failRows;
      }
      if (this.filterRecordsBy === 'successful') {
        return this.successRows;
      }
      return this.data;
    },
    successCount() {
      return this.successRows.length;
    },
    failCount() {
      return this.failRows.length;
    },
  },
});
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 90%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: auto;
  max-height: 100vh;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
