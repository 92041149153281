<template>
  <validation-observer v-slot="{ invalid }">
    <div class="modal-card" style="width: 600px">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ titleText }}</p>
      </header>
      <section class="modal-card-body">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          name="DP Number"
        >
          <b-field horizontal label="DPNumber">
            <b-input v-model="localInventory.DPNumber" type="number">{{
              localInventory.DPNumber
            }}</b-input>
          </b-field>
          <span class="help has-text-danger">
            {{ errors[0] }}
          </span>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          rules="required"
          name="Serial Number"
        >
          <b-field horizontal label="Serial Number">
            <b-input v-model="localInventory.serialNumber" :disabled="update">{{
              localInventory.serialNumber
            }}</b-input>
          </b-field>
          <span class="help has-text-danger">
            {{ errors[0] }}
          </span>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          rules="required"
          name="Item Number"
        >
          <b-field horizontal label="Item Number">
            <b-input v-model="localInventory.itemNumber">{{
              localInventory.itemNumber
            }}</b-input>
          </b-field>
          <span class="help has-text-danger">
            {{ errors[0] }}
          </span>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          rules="required"
          name="Install Date"
        >
          <b-field horizontal label="Install Date">
            <b-datepicker
              v-model="installDate"
              :first-day-of-week="1"
              trap-focus
            >
            </b-datepicker>
          </b-field>
          <span class="help has-text-danger">
            {{ errors[0] }}
          </span>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          rules="required"
          name="Quantity"
        >
          <b-field horizontal label="Quantity">
            <b-input v-model="localInventory.quantity" type="number">{{
              localInventory.quantity
            }}</b-input>
          </b-field>
          <span class="help has-text-danger">
            {{ errors[0] }}
          </span>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          rules="required"
          name="Inventory Type"
        >
          <b-field label="Inventory Type" horizontal>
            <b-select
              v-model="localInventory.inventoryType"
              placeholder="Inventory Type"
            >
              <option
                v-for="item in inventoryTypes"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name }}
              </option>
            </b-select>
          </b-field>
          <span class="help has-text-danger">
            {{ errors[0] }}
          </span>
        </validation-provider>

        <validation-provider v-slot="{ errors }" rules="required" name="Status">
          <b-field horizontal label="Status">
            <b-select
              v-model="localInventory.status"
              placeholder="Select a status"
            >
              <option value="customer">customer</option>
              <option value="deinstalled">deinstalled</option>
            </b-select>
          </b-field>
          <span class="help has-text-danger">
            {{ errors[0] }}
          </span>
        </validation-provider>

        <b-field horizontal label="Description">
          <b-input v-model="localInventory.description">{{
            localInventory.description
          }}</b-input>
        </b-field>

        <b-field horizontal label="Thorough Due">
          <b-input v-model="localInventory.thoroughDue">{{
            localInventory.thoroughDue
          }}</b-input>
        </b-field>
        <b-field horizontal label="Reason">
          <b-select v-model="localInventory.reason">
            <option
              v-for="option in $configData.inventory.reasons"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </b-select>
        </b-field>
        <b-field horizontal label="Workorder Number">
          <b-input v-model="localInventory.workOrderNumber" disabled>{{
            localInventory.workOrderNumber
          }}</b-input>
        </b-field>
        <b-field horizontal label="Resource ID">
          <b-input v-model="localInventory.resourceId" disabled>{{
            localInventory.resourceId
          }}</b-input>
        </b-field>
        <div class="has-text-centered">
          <inventory-map
            v-if="inventoryItem.latitude && inventoryItem.longitude"
            :latitude="inventoryItem.latitude"
            :longitude="inventoryItem.longitude"
          />
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button type="is-primary" @click="$parent.close()"> Close </b-button>
        <b-button :disabled="invalid" type="is-success" @click="save">
          Save
        </b-button>
      </footer>
    </div>
  </validation-observer>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate';

import InventoryMap from './InventoryMap';

setInteractionMode('aggressive');

export default defineComponent({
  name: 'InventoryEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    InventoryMap,
  },
  props: {
    inventoryItem: {
      type: Object,
      required: false,
      default: () => {
        return {
          DPNumber: '',
          serialNumber: '',
          installDate: undefined,
          modifiedDate: undefined,
          status: 'customer',
          inventoryType: undefined,
          itemNumber: undefined,
          quantity: undefined,
          modelNumber: undefined,
          description: undefined,
          thoroughDue: undefined,
          reason: undefined,
          latLng: undefined,
        };
      },
      // TODO: add validator
    },
    update: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      localInventory: {
        DPNumber: '',
        serialNumber: '',
        installDate: undefined,
        status: undefined,
        modifiedDate: undefined,
        inventoryType: undefined,
        latitude: undefined,
        longitude: undefined,
        itemNumber: undefined,
        quantity: undefined,
        modelNumber: undefined,
        description: undefined,
        thoroughDue: undefined,
        reason: undefined,
      },
      inventoryTypes: this.$configData.inventory.inventoryTypes,
    };
  },
  computed: {
    titleText() {
      return this.update ? 'Update Inventory Item' : 'Create Inventory Item';
    },
    installDate: {
      get() {
        if (!this.localInventory.installDate) return undefined;
        return new Date(this.localInventory.installDate);
      },
      set(value) {
        this.localInventory.installDate = value;
      },
    },
    formattedInstallDate() {
      if (!this?.installDate) return '';
      const installDateMom = this.$moment(this.installDate);
      return installDateMom.format('YYYY-MM-DD');
    },
  },
  created() {
    this.localInventory = this.$copy(this.inventoryItem);
  },
  methods: {
    save() {
      if (this.formattedInstallDate) {
        this.localInventory.installDate = this.formattedInstallDate;
      }
      if (this.update) {
        this.$emit('inventory-update', this.localInventory);
      }
      this.$emit('inventory-create', this.localInventory);
      this.$parent.close();
    },
  },
});
</script>

<style></style>
