/* eslint-disable vue/attribute-hyphenation */
<template>
  <div class="container is-fluid">
    <b-sidebar
      type="is-white"
      :fullheight="true"
      :fullwidth="false"
      :overlay="false"
      :right="false"
      :open.sync="open"
      v:on="$listeners"
    >
      <search-side-bar @search="onSearch" @close="onClose" />
    </b-sidebar>
    <b-sidebar
      type="is-white"
      :fullheight="true"
      :fullwidth="false"
      :overlay="false"
      :right="true"
      :open.sync="openUpload"
      v:on="$listeners"
    >
      <inventory-upload @load-complete="onLoadComplete"></inventory-upload>
    </b-sidebar>
    <div
      style="display: flex; justify-content: space-between; padding: 1em 0em"
    >
      <div class="buttons">
        <b-button
          icon-left="search-plus"
          size="is-small"
          type="is-primary"
          @click="open = true"
          ><span class="has-text-weight-semibold">Search</span></b-button
        >
        <b-button
          icon-left="search-minus"
          type="is-dark"
          size="is-small"
          @click="onClearSearch()"
          ><span class="has-text-weight-semibold">Clear Search</span></b-button
        >
      </div>
      <div class="buttons">
        <b-button
          icon-left="download"
          size="is-small"
          type="is-primary"
          @click="getDataFromApi(searchCriteria)"
          ><span class="has-text-weight-semibold">Fetch Data</span></b-button
        >
        <b-button
          type="is-primary"
          size="is-small"
          icon-left="file-csv"
          @click="getDataFromApi(searchCriteria, true)"
          ><span class="has-text-weight-semibold"
            >Download as CSV</span
          ></b-button
        >
      </div>
      <div class="buttons">
        <b-button
          icon-left="warehouse-alt"
          size="is-small"
          type="is-success"
          @click="openCreateInventory"
          ><span class="has-text-weight-semibold"
            >Create Inventory</span
          ></b-button
        >
        <b-button
          icon-left="warehouse"
          size="is-small"
          type="is-success"
          @click="openUpload = true"
          ><span class="has-text-weight-semibold"
            >Upload Inventory</span
          ></b-button
        >
      </div>
      <div class="field deinstall-vertical-center">
        <b-checkbox v-model="hideDeinstalled">
          {{ hideDeinstalled ? 'Hiding Deinstalled' : 'Showing Deinstalled' }}
        </b-checkbox>
      </div>
      <div class="has-text-weight-semibold">
        Filtered records: {{ $n(totalReturnedInventory) }}
        <br />
        Total inventory records: {{ $n(totalInventory) }}
      </div>
    </div>
    <b-table
      :data="inventories"
      paginated
      striped
      narrowed
      backend-pagination
      backend-sorting
      :default-sort="[sortField, sortOrder]"
      :default-sort-direction="defaultSortOrder"
      :total="totalReturnedInventory"
      :loading="loading"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      scrollable
      hoverable
      :per-page="itemsPerPage"
      sticky-header
      height="720px"
      @page-change="onPageChange"
      @sort="onSort"
      @click="onRowClick"
    >
      <b-table-column
        field="DPNumber"
        label="DP Number"
        sortable
        header-class="is-sticky-column-dp"
        cell-class="is-sticky-column-dp"
      >
        <template #default="props">
          {{ props.row.DPNumber }}
        </template>
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="serialNumber"
        label="Serial Number"
        sortable
        sticky
        cell-class="is-sticky-column-serial"
        header-class="is-sticky-column-serial"
      >
        {{ props.row.serialNumber }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="itemNumber"
        label="Item Number"
        sortable
      >
        {{ props.row.itemNumber }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="installDate"
        label="Install Date"
        sortable
      >
        {{ formatDate(props.row.installDate) }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="modifiedDate"
        label="Modified Date"
        sortable
      >
        {{ formatDate(props.row.modifiedDate) }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="inventoryType"
        label="Inventory Type"
        sortable
      >
        {{ props.row.inventoryType }}
      </b-table-column>

      <b-table-column v-slot="props" field="status" label="Status" sortable>
        {{ props.row.status }}
      </b-table-column>

      <b-table-column v-slot="props" field="quantity" label="Quantity" sortable>
        {{ props.row.quantity }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="resourceId"
        label="Engineer ID"
        :visible="false"
        sortable
      >
        {{ props.row.resourceId }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="description"
        label="Description"
        sortable
      >
        {{ props.row.description }}
      </b-table-column>

      <b-table-column v-slot="props" field="latitude" label="Lat/Lng" sortable>
        {{
          props.row.latitude
            ? `${props.row.latitude}/${props.row.longitude}`
            : ''
        }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="thoroughDue"
        label="Thorough Due"
        sortable
      >
        {{ props.row.thoroughDue }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="reason"
        label="Reason Label"
        sortable
      >
        {{ props.row.reason }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="ofscId"
        label="OFSC ID"
        :visible="false"
        sortable
      >
        {{ props.row.ofscId }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="inventoryId"
        label="Inventory ID"
        :visible="false"
      >
        {{ props.row.inventoryId }}
      </b-table-column>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="sad-tear" size="is-large"> </b-icon>
            </p>
            <p>Nothing here, yet.</p>
          </div>
        </section>
      </template>
    </b-table>
    <b-button @click="getCSVZip()">Download File</b-button>
    <p v-if="error" class="has-text-danger">{{ error }}</p>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters, mapActions } from 'vuex';
import { format } from 'date-fns';
import FileSaver from 'file-saver';
import {
  updateInventoryItem,
  createInventoryItem,
  searchInventory,
  getInventoryCsv,
} from '@/services/inventoryService';
import InventoryEdit from './InventoryEdit';
import SearchSideBar from './InventorySearchSideBar';
import InventoryUpload from './InventoryUpload';

export default defineComponent({
  name: 'InventoryDisplay',
  components: {
    SearchSideBar,
    InventoryUpload,
  },
  data() {
    return {
      inventories: [],
      totalReturnedInventory: 0,
      totalInventory: 0,
      loading: false,
      itemsPerPage: 20,
      error: undefined,
      sortField: 'DPNumber',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1,
      perPage: 20,
      open: false,
      openUpload: false,
      hideDeinstalled: true,
      fileUrl: '',
      csvData: undefined,
    };
  },
  computed: {
    ...mapGetters('storeInventory', ['searchCriteria']),
  },
  watch: {
    hideDeinstalled: {
      handler() {
        this.getDataFromApi(this.searchCriteria);
      },
    },
    fileUrl: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.pollForFile();
        }
      },
    },
    csvData: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.download(newVal);
        }
      },
    },
  },
  mounted() {
    this.getDataFromApi(this.searchCriteria);
  },
  methods: {
    ...mapActions('storeInventory', ['setInitialState']),
    download(data) {
      const blob = new Blob([data]);
      FileSaver.saveAs(blob, 'inventory-data.zip');
    },
    async getDataFromApi(searchCriteria, asCsv = false) {
      this.loading = true;
      try {
        this.error = null;
        let offset = 0;
        if (this.inventories.length === 0) {
          offset = 0;
        } else {
          offset = (this.page - 1) * this.itemsPerPage;
        }
        const retrieveOptions = {
          totalToRetrieve: this.itemsPerPage,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          offset: offset,
          hideDeinstalled: this.hideDeinstalled,
          asCsv,
        };
        if (searchCriteria) {
          retrieveOptions.searchCriteria = searchCriteria;
          if (!asCsv) {
            const { items, total, overallTotal } = await searchInventory(
              retrieveOptions,
            );
            this.inventories = items;
            this.totalReturnedInventory = total;
            this.totalInventory = overallTotal;
          } else {
            const data = await searchInventory(retrieveOptions);
            this.fileUrl = data.url;
          }
        }
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },
    async pollForFile() {
      this.loading = true;
      setTimeout(async () => {
        const data = await getInventoryCsv({ url: this.fileUrl });
        if (data.byteLength === 0) {
          this.pollForFile();
        } else {
          this.csvData = data;
          this.loading = false;
        }
      }, 20000);
    },
    onPageChange(page) {
      this.page = page;
      this.getDataFromApi(this.searchCriteria);
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.getDataFromApi(this.searchCriteria);
    },
    onRowClick(inventory) {
      this.$buefy.modal.open({
        parent: this,
        component: InventoryEdit,
        props: {
          inventoryItem: inventory,
          update: true,
        },
        events: {
          'inventory-update': this.handleUpdate,
        },
        hasModalCard: true,
      });
    },
    openCreateInventory() {
      this.$buefy.modal.open({
        parent: this,
        component: InventoryEdit,
        props: {
          update: false,
        },
        events: {
          'inventory-create': this.handleCreate,
        },
        hasModalCard: true,
      });
    },
    formatDate(dateString) {
      if (!dateString) return undefined;
      const date = new Date(dateString);
      try {
        return format(date, 'dd-MM-yyyy');
      } catch (err) {
        return undefined;
      }
    },
    async handleUpdate(inventory) {
      try {
        this.error = null;
        inventory.modifiedDate = new Date();
        await updateInventoryItem(inventory);
        await this.getDataFromApi(this.searchCriteria);
        this.$buefy.toast.open({
          duration: 3000,
          message: `Inventory Item for ${inventory.DPNumber} updated successfully`,
          type: 'is-success',
        });
      } catch (err) {
        this.error = err;
        this.$buefy.toast.open({
          duration: 3000,
          message: `Inventory Item update for ${inventory.DPNumber} failed`,
          type: 'is-danger',
        });
      }
    },
    async handleCreate(inventory) {
      try {
        this.error = null;
        inventory.modifiedDate = new Date();
        await createInventoryItem(inventory);
        await this.getDataFromApi(this.searchCriteria);
        this.$buefy.toast.open({
          duration: 3000,
          message: `Inventory Item for ${inventory.DPNumber} added successfully`,
          type: 'is-success',
        });
      } catch (err) {
        this.error = err;
        this.$buefy.toast.open({
          duration: 3000,
          message: `Creation of inventory item for ${inventory.DPNumber} failed`,
          type: 'is-danger',
        });
      }
    },
    onSearch() {
      this.open = false;
      try {
        this.getDataFromApi(this.searchCriteria);
      } catch (err) {
        this.error = err;
      }
    },
    onClose() {
      this.open = false;
    },
    onClearSearch() {
      this.setInitialState();
      this.getDataFromApi(this.searchCriteria);
    },
    onLoadComplete() {
      this.openUpload = false;
      this.getDataFromApi(this.searchCriteria);
    },
  },
});
</script>

<style lang="scss">
.is-sticky-column-dp {
  background: #035bbe !important;
  color: white !important;
  font-weight: bolder;
}
.is-sticky-column-serial {
  background: #167df0 !important;
  color: white !important;
  font-weight: bolder;
}
.deinstall-vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
